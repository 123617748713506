<template>
  <v-container>
    <back-toolbar title="login.forgotPassword"></back-toolbar>
    <v-row class="mt-16 mx-3">
      <v-col cols="12">
        <v-text-field
          :class="$store.state.settings.dark ? 'textfield-dark' : ''"
          v-model="email"
          :error-messages="emailErrors"
          solo
          :placeholder="$t('profile.email')"
          required
          @input="$v.email.$touch()"
          @blur="getEmailErrors()"
        ></v-text-field>
        <v-btn
          class="gradient Heading-White-H4-18-Center"
          color="primary"
          width="100%"
          min-height="40"
          large
          depressed
          @click="forgotPw"
        >
          {{ $t("profile.resetPassword") }}
        </v-btn>
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          hide-overlay
          fullscreen
        >
          <v-card>
            <v-card-title class="justify-center pt-12 HeadingDarkH2-28Center">
              Check your email
            </v-card-title>
            <v-card-text class="mt-12 px-8 Text-Dark-Text-1---18-Center">
              {{ $t("profile.resetMessage") }}
            </v-card-text>
            <v-card-actions class="px-6"
              ><v-btn
                class="gradient mt-12 Heading-White-H4-18-Center"
                width="100%"
                depressed
                height="48"
                color="primary"
                @click="(dialog = false), $router.back()"
                >Ok</v-btn
              ></v-card-actions
            >
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import BackToolbar from "@/components/app/common/BackToolbar";
export default {
  data: () => ({
    email: "",
    dialog: false,
    emailErrors: [],
  }),
  components: {
    BackToolbar,
  },
  validations: {
    email: { required, email },
  },
  methods: {
    async forgotPw() {
      this.$v.email.$touch();

      this.getEmailErrors();

      if (!this.$v.email.$invalid) {
        this.dialog = true;
        this.$store.commit("session/CLEAR_AUTH_ERROR");
        await this.$store.dispatch("session/sendResetEmail", this.email);
      }
    },

    getEmailErrors() {
      const errors = [];

      if (!this.$v.email.$dirty) {
        this.emailErrors = errors;
        return false;
      }

      !this.$v.email.email && errors.push(this.$t("profile.validMail"));
      !this.$v.email.required && errors.push(this.$t("profile.requireMail"));

      this.emailErrors = errors;
      return errors.length > 0;
    },
  },
};
</script>
